import { RightControllerService } from '@/__generated__/FrontApi';
import Sgds10 from '@/assets/img/advocacy-for-children/sgds_10@2x.png';
import Sgds10H from '@/assets/img/advocacy-for-children/sgds_10h@2x.png';
import Sgds11 from '@/assets/img/advocacy-for-children/sgds_11@2x.png';
import Sgds11H from '@/assets/img/advocacy-for-children/sgds_11h@2x.png';
import Sgds12 from '@/assets/img/advocacy-for-children/sgds_12@2x.png';
import Sgds12H from '@/assets/img/advocacy-for-children/sgds_12h@2x.png';
import Sgds13 from '@/assets/img/advocacy-for-children/sgds_13@2x.png';
import Sgds13H from '@/assets/img/advocacy-for-children/sgds_13h@2x.png';
import Sgds14 from '@/assets/img/advocacy-for-children/sgds_14@2x.png';
import Sgds14H from '@/assets/img/advocacy-for-children/sgds_14h@2x.png';
import Sgds15 from '@/assets/img/advocacy-for-children/sgds_15@2x.png';
import Sgds15H from '@/assets/img/advocacy-for-children/sgds_15h@2x.png';
import Sgds16 from '@/assets/img/advocacy-for-children/sgds_16@2x.png';
import Sgds16H from '@/assets/img/advocacy-for-children/sgds_16h@2x.png';
import Sgds17 from '@/assets/img/advocacy-for-children/sgds_17@2x.png';
import Sgds17H from '@/assets/img/advocacy-for-children/sgds_17h@2x.png';
import Sgds1 from '@/assets/img/advocacy-for-children/sgds_1@2x.png';
import Sgds1H from '@/assets/img/advocacy-for-children/sgds_1h@2x.png';
import Sgds2 from '@/assets/img/advocacy-for-children/sgds_2@2x.png';
import Sgds2H from '@/assets/img/advocacy-for-children/sgds_2h@2x.png';
import Sgds3 from '@/assets/img/advocacy-for-children/sgds_3@2x.png';
import Sgds3H from '@/assets/img/advocacy-for-children/sgds_3h@2x.png';
import Sgds4 from '@/assets/img/advocacy-for-children/sgds_4@2x.png';
import Sgds4H from '@/assets/img/advocacy-for-children/sgds_4h@2x.png';
import Sgds5 from '@/assets/img/advocacy-for-children/sgds_5@2x.png';
import Sgds5H from '@/assets/img/advocacy-for-children/sgds_5h@2x.png';
import Sgds6 from '@/assets/img/advocacy-for-children/sgds_6@2x.png';
import Sgds6H from '@/assets/img/advocacy-for-children/sgds_6h@2x.png';
import Sgds7 from '@/assets/img/advocacy-for-children/sgds_7@2x.png';
import Sgds7H from '@/assets/img/advocacy-for-children/sgds_7h@2x.png';
import Sgds8 from '@/assets/img/advocacy-for-children/sgds_8@2x.png';
import Sgds8H from '@/assets/img/advocacy-for-children/sgds_8h@2x.png';
import Sgds9 from '@/assets/img/advocacy-for-children/sgds_9@2x.png';
import Sgds9H from '@/assets/img/advocacy-for-children/sgds_9h@2x.png';
import Button from '@/components/Button';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup from '@/components/Form/FormGroup';
import FormTextTip from '@/components/Form/FormTextTip';
import LabelText from '@/components/Form/LabelText';
import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import AddressButton from '@/components/Input/AddressButton';
import InputCheckbox from '@/components/Input/InputCheckbox';
import InputDate from '@/components/Input/InputDate';
import InputRadio, { InputRadioGroup } from '@/components/Input/InputRadio';
import InputText from '@/components/Input/InputText';
import LinkSafe from '@/components/LinkSafe';
import Section from '@/components/Section';
import Select from '@/components/Select';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { emailRegex, mobileRegex } from '@/helpers/ValidationHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { FormButton, RightCol } from '@/page-blocks/auth/AuthCommon';
import { usePopupStore } from '@/stores/PopupStore';
import { PageProps } from 'gatsby';
import React, { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `유니세프한국위원회`,
    tel: `02-724-8573`,
    email: `cre@unicef.or.kr`,
  },
];
const chkData = [
  { id: 1, no: 1, label: `빈곤 퇴치`, name: `answer1` },
  { id: 2, no: 2, label: `기아 종식`, name: `answer2` },
  { id: 3, no: 3, label: `건강과 복지`, name: `answer3` },
  { id: 4, no: 4, label: `양질의 교육`, name: `answer4` },
  { id: 5, no: 5, label: `성 평등`, name: `answer5` },
  { id: 6, no: 6, label: `깨끗한 물과 위생`, name: `answer6` },
  { id: 7, no: 7, label: `지속가능한 에너지`, name: `answer7` },
  { id: 8, no: 8, label: `양질의 일자리와 경제 성장`, name: `answer8` },
  { id: 9, no: 9, label: `혁신과 사회기반시설`, name: `answer9` },
  { id: 10, no: 10, label: `불평등 감소`, name: `answer10` },
  { id: 11, no: 11, label: `지속가능한 도시와 공동체`, name: `answer11` },
  { id: 12, no: 12, label: `책임 있는 소비와 생산`, name: `answer12` },
  { id: 13, no: 13, label: `기후변화 대응`, name: `answer13` },
  { id: 14, no: 14, label: `해양 생태계 보호`, name: `answer14` },
  { id: 15, no: 15, label: `육상 생태계 보호`, name: `answer15` },
  { id: 16, no: 16, label: `평화와 정의, 효과적인 제도`, name: `answer16` },
  { id: 17, no: 17, label: `지구촌 협력`, name: `answer17` },
];

const Toggle = styled.ul`
  align-items: start !important;
  & > li {
    width: 33.33%;
    margin-bottom: 36px;
    font-size: 14px;
    line-height: 2;
    .chk-label {
      display: block;
      width: 100%;
      padding: 0;
    }

    .obj {
      height: auto;
      border: 0 !important;
      position: relative;
      width: 100%;
      padding-top: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      &::after {
        display: none !important;
      }
    }
    &:nth-child(1) {
      .obj {
        background-image: url(${Sgds1});
      }
      input:checked + .obj {
        background-image: url(${Sgds1H});
      }
    }
    &:nth-child(2) {
      .obj {
        background-image: url(${Sgds2});
      }
      input:checked + .obj {
        background-image: url(${Sgds2H});
      }
    }
    &:nth-child(3) {
      .obj {
        background-image: url(${Sgds3});
      }
      input:checked + .obj {
        background-image: url(${Sgds3H});
      }
    }
    &:nth-child(4) {
      .obj {
        background-image: url(${Sgds4});
      }
      input:checked + .obj {
        background-image: url(${Sgds4H});
      }
    }
    &:nth-child(5) {
      .obj {
        background-image: url(${Sgds5});
      }
      input:checked + .obj {
        background-image: url(${Sgds5H});
      }
    }
    &:nth-child(6) {
      .obj {
        background-image: url(${Sgds6});
      }
      input:checked + .obj {
        background-image: url(${Sgds6H});
      }
    }
    &:nth-child(7) {
      .obj {
        background-image: url(${Sgds7});
      }
      input:checked + .obj {
        background-image: url(${Sgds7H});
      }
    }
    &:nth-child(8) {
      .obj {
        background-image: url(${Sgds8});
      }
      input:checked + .obj {
        background-image: url(${Sgds8H});
      }
    }
    &:nth-child(9) {
      .obj {
        background-image: url(${Sgds9});
      }
      input:checked + .obj {
        background-image: url(${Sgds9H});
      }
    }
    &:nth-child(10) {
      .obj {
        background-image: url(${Sgds10});
      }
      input:checked + .obj {
        background-image: url(${Sgds10H});
      }
    }
    &:nth-child(11) {
      .obj {
        background-image: url(${Sgds11});
      }
      input:checked + .obj {
        background-image: url(${Sgds11H});
      }
    }
    &:nth-child(12) {
      .obj {
        background-image: url(${Sgds12});
      }
      input:checked + .obj {
        background-image: url(${Sgds12H});
      }
    }
    &:nth-child(13) {
      .obj {
        background-image: url(${Sgds13});
      }
      input:checked + .obj {
        background-image: url(${Sgds13H});
      }
    }
    &:nth-child(14) {
      .obj {
        background-image: url(${Sgds14});
      }
      input:checked + .obj {
        background-image: url(${Sgds14H});
      }
    }
    &:nth-child(15) {
      .obj {
        background-image: url(${Sgds15});
      }
      input:checked + .obj {
        background-image: url(${Sgds15H});
      }
    }
    &:nth-child(16) {
      margin-bottom: 12px;
      .obj {
        background-image: url(${Sgds16});
      }
      input:checked + .obj {
        background-image: url(${Sgds16H});
      }
    }
    &:nth-child(17) {
      margin-bottom: 12px;
      .obj {
        background-image: url(${Sgds17});
      }
      input:checked + .obj {
        background-image: url(${Sgds17H});
      }
    }
  }
  ${breakpoint(`tablet`)} {
    margin-left: -5px;
    margin-right: -5px;
    & > li {
      padding: 0 5px;
      margin-bottom: 40px;
      font-size: 12px;
    }
  }
`;
const AgreeHead = styled.div`
  line-height: 1.2;
  padding: 24px 0 16px;
  color: #2d2926;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;

  border-top: 1px solid #e5e6e8;
  border-bottom: 1px solid #b7b8ba;

  h2 {
    margin-bottom: 0 !important;
  }
`;

const StepHead = styled.div`
  line-height: 1.2;
  padding: 29px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;

  &.agree-head {
    margin-top: 40px;
    border-top: 1px solid #e5e6e8;
    border-bottom: 1px solid #b7b8ba;
    padding-bottom: 16px;
  }
`;
const AgreeTable = styled.div`
  margin: 8px 0;
  table {
    margin-top: 0;
    border-top: 2px solid #2d2926;
    th {
      font-weight: bold;
      text-align: center;
    }
    td {
      text-align: center;
    }
    th,
    td {
      height: auto;
      padding: 12px 4px;
      font-size: 16px;
      line-height: normal;
      border-top: none;
    }
    td {
      border-bottom: 1px solid #ddd;
    }
  }
  ${breakpoint(640)} {
    table {
      th,
      td {
        font-size: 14px;
      }
    }
  }
`;
const FormContainer = styled.div`
  max-width: 544px;
  margin: 0 auto;

  header {
    border-bottom: 1px solid #e5e6e8;
    padding-bottom: 40px;
    margin-bottom: 32px;

    .link-item {
      color: #1cabe2;
    }
  }

  article {
    margin-bottom: 96px;
    &:last-child {
      margin-bottom: 0;
    }

    h2 {
      margin-bottom: 32px;
    }

    ${FormGroup} {
      &.flex {
        margin: 0 -10px;
        margin-bottom: 24px;

        .col {
          width: 33.33%;
          padding: 0 10px;
        }
      }
      .txt-form {
        display: flex;
        align-items: center;

        .bar {
          width: 34px;
          padding-left: 4px;
          text-align: left;
        }
      }
    }
    .tip {
      margin-top: 16px;
    }

    .chk-label + .input {
      margin-top: 8px;
    }

    .radio-list {
      & > li {
        margin-bottom: 33px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .btn-wrap {
    margin-top: 96px;
  }
  ${breakpoint(`tablet`)} {
    header {
      padding-bottom: 24px;
      margin-bottom: 24px;
    }

    article {
      margin-bottom: 72px;
      h2 {
        margin-bottom: 24px;
      }

      ${FormGroup} {
        &.flex {
          .col {
            &.c1 {
              width: 50%;
              margin-bottom: 24px;
            }
            &.c2 {
              width: 100%;
            }
          }
        }
        .txt-form {
          display: flex;
          align-items: center;

          .bar {
            width: 34px;
            padding-left: 4px;
            text-align: left;
          }
        }
      }
      .tip {
        margin-top: 16px;
      }

      .chk-label + .input {
        margin-top: 8px;
      }

      .radio-list {
        & > li {
          margin-bottom: 33px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .btn-wrap {
      margin-top: 73px;
    }
  }
`;

interface ApplyData {
  answer1: boolean;
  answer2: boolean;
  answer3: boolean;
  answer4: boolean;
  answer5: boolean;
  answer6: boolean;
  answer7: boolean;
  answer8: boolean;
  answer9: boolean;
  answer10: boolean;
  answer11: boolean;
  answer12: boolean;
  answer13: boolean;
  answer14: boolean;
  answer15: boolean;
  answer16: boolean;
  answer17: boolean;
  answer18: string;
  contact: string;
  educationDate: string;
  route: string;
  name: string;
  schoolName: string;
  studentCnt: string;
  zipCode: string;
  address: string;
  addressDetail: string;
  classNum: string;
  email: string;
  isAgreeYn1: boolean;
  isAgreeYn2: boolean;
  grade: string;
  policyAgree: boolean;
}

const Apply: React.FC<PageProps> = ({ location }) => {
  const popupStore = usePopupStore();
  const [routeEtc, setRouteEtc] = useState(false);
  const [answer, setAnswer] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm<ApplyData>({
    defaultValues: {
      isAgreeYn1: true,
      isAgreeYn2: false,
      answer1: false,
      answer2: false,
      answer3: false,
      answer4: false,
      answer5: false,
      answer6: false,
      answer7: false,
      answer8: false,
      answer9: false,
      answer10: false,
      answer11: false,
      answer12: false,
      answer13: false,
      answer14: false,
      answer15: false,
      answer16: false,
      answer17: false,
    },
  });
  // const route = watch(`route`);
  const onSubmitForm: SubmitHandler<ApplyData> = useCallback(
    async ({
      isAgreeYn1,
      isAgreeYn2,
      answer1,
      answer2,
      answer3,
      answer4,
      answer5,
      answer6,
      answer7,
      answer8,
      answer9,
      answer10,
      answer11,
      answer12,
      answer13,
      answer14,
      answer15,
      answer16,
      answer17,
      ...formData
    }) => {
      console.log(formData, isAgreeYn1, isAgreeYn2);
      try {
        const {
          resultCode,
          resultMessage,
        } = await RightControllerService.insertWllApplyUsingPost({
          // isAgreeYn1: isAgreeYn1 ? `Y` : `N`,
          isAgreeYn1: `Y`,
          isAgreeYn2: isAgreeYn2 ? `Y` : `N`,
          answer1: answer1 ? `Y` : `N`,
          answer2: answer2 ? `Y` : `N`,
          answer3: answer3 ? `Y` : `N`,
          answer4: answer4 ? `Y` : `N`,
          answer5: answer5 ? `Y` : `N`,
          answer6: answer6 ? `Y` : `N`,
          answer7: answer7 ? `Y` : `N`,
          answer8: answer8 ? `Y` : `N`,
          answer9: answer9 ? `Y` : `N`,
          answer10: answer10 ? `Y` : `N`,
          answer11: answer11 ? `Y` : `N`,
          answer12: answer12 ? `Y` : `N`,
          answer13: answer13 ? `Y` : `N`,
          answer14: answer14 ? `Y` : `N`,
          answer15: answer15 ? `Y` : `N`,
          answer16: answer16 ? `Y` : `N`,
          answer17: answer17 ? `Y` : `N`,
          ...formData,
        });
        // 성공후 폼 리셋
        popupStore.show(`신청되었습니다.`);
        window.location.href = `/what-we-do/advocacy-for-children/child-rights-education#applyBtn`;
        reset();
      } catch (e) {
        popupStore.show(`우편 신청에 실패했습니다. 다시 시도해주세요.`);
      }
    },
    [popupStore, reset],
  );

  return (
    <LayoutWithTitle
      location={location}
      paddingBtt
      title="교육 참가 신청하기"
      description="World's Largest Lesson"
    >
      <Section className="by-sub-main-layout">
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Container>
            <FormContainer>
              <header>
                <strong className="dec">
                  참가 신청 시 SDGs 포스터를 보내드리며, 교육자료는 웹사이트에서
                  받을 수 있습니다. <br />
                  교육 후기를{` `}
                  <LinkSafe to="mailto:cre@unicef.or.kr" className="link-item">
                    cre@unicef.or.kr
                  </LinkSafe>
                  로 보내주시면 참가 확인증과 소정의 기념품을 드립니다.
                </strong>
              </header>

              <div className="article-wrap">
                <article>
                  <h2>
                    <Tit size="s4-1">개인정보 입력</Tit>
                  </h2>

                  <FormGroup>
                    <LabelText require>학교명</LabelText>
                    <InputText
                      type="text"
                      placeholder="학교명 입력"
                      name="schoolName"
                      ref={register({
                        required: {
                          value: true,
                          message: `학교명을 입력해주세요.`,
                        },
                      })}
                    />
                    {errors.schoolName && (
                      <ErrorMessage>{errors.schoolName.message}</ErrorMessage>
                    )}
                  </FormGroup>
                  <FormGroup className="flex">
                    <div className="col c1">
                      <LabelText require>학년</LabelText>
                      <div className="txt-form">
                        <Select
                          name="grade"
                          ref={register({
                            required: {
                              value: true,
                              message: `학년을 선택해주세요.`,
                            },
                          })}
                        >
                          +<option value="">선택</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                        </Select>
                        <span className="bar">학년</span>
                      </div>
                    </div>
                    <div className="col c1">
                      <LabelText require>반</LabelText>
                      <div className="txt-form">
                        <InputText
                          type="number"
                          placeholder="0"
                          name="classNum"
                          ref={register({
                            required: {
                              value: true,
                              message: `반을 입력해주세요.`,
                            },
                            pattern: {
                              value: /[0-9]/g,
                              message: `반은 숫자만 입력해주세요.`,
                            },
                          })}
                        />
                        <span className="bar">반</span>
                      </div>
                    </div>
                    <div className="col c2">
                      <LabelText require>학생 수</LabelText>
                      <div className="txt-form">
                        <InputText
                          type="number"
                          placeholder="0"
                          name="studentCnt"
                          ref={register({
                            required: {
                              value: true,
                              message: `학생 수를 입력해주세요.`,
                            },
                            pattern: {
                              value: /[0-9]/g,
                              message: `학생 수는 숫자만 입력해주세요.`,
                            },
                          })}
                        />
                        <span className="bar">명</span>
                      </div>
                    </div>
                    <Row>
                      {errors.grade && (
                        <ErrorMessage>{errors.grade.message}</ErrorMessage>
                      )}
                      {errors.classNum && (
                        <ErrorMessage>{errors.classNum.message}</ErrorMessage>
                      )}
                      {errors.studentCnt && (
                        <ErrorMessage>{errors.studentCnt.message}</ErrorMessage>
                      )}
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <LabelText require>교육 예정일</LabelText>
                    <Row>
                      <InputDate
                        type="text"
                        placeholder="2021.01.01"
                        name="educationDate"
                        ref={register({
                          required: {
                            value: true,
                            message: `날짜를 선택해주세요.`,
                          },
                        })}
                      />
                    </Row>
                    <Row>
                      {errors.educationDate && (
                        <ErrorMessage>
                          {errors.educationDate.message}
                        </ErrorMessage>
                      )}
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <LabelText require>신청인</LabelText>
                    <Row>
                      <InputText
                        type="text"
                        placeholder="신청인명 입력"
                        name="name"
                        ref={register({
                          required: {
                            value: true,
                            message: `신청인명을 입력해주세요.`,
                          },
                        })}
                      />
                    </Row>
                    <Row>
                      {errors.name && (
                        <ErrorMessage>{errors.name.message}</ErrorMessage>
                      )}
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <LabelText require>연락처</LabelText>
                    <Row>
                      <InputText
                        type="text"
                        placeholder="휴대폰번호 입력 ( &lsquo;-&rsquo; 제외 )"
                        name="contact"
                        ref={register({
                          required: {
                            value: true,
                            message: `휴대폰번호를 입력해주세요.`,
                          },
                          pattern: {
                            value: mobileRegex,
                            message: `올바른 휴대폰번호가 아닙니다.`,
                          },
                          validate: {
                            isValidRange: (value) =>
                              (value.substr(0, 3) === `010` &&
                                value.length === 11) ||
                              (value.substr(0, 3) !== `010` &&
                                value.length === 10) ||
                              `휴대폰번호 자릿수를 확인해주세요`,
                          },
                        })}
                      />
                    </Row>
                    <Row>
                      {errors.contact && (
                        <ErrorMessage>{errors.contact.message}</ErrorMessage>
                      )}
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <LabelText require>이메일</LabelText>
                    <Row>
                      <InputText
                        type="text"
                        placeholder="이메일주소 입력"
                        name="email"
                        ref={register({
                          required: {
                            value: true,
                            message: `이메일주소를 입력해주세요.`,
                          },
                          pattern: {
                            value: emailRegex,
                            message: `올바른 이메일 형식이 아닙니다.`,
                          },
                        })}
                      />
                    </Row>
                    <Row>
                      {errors.email && (
                        <ErrorMessage>{errors.email.message}</ErrorMessage>
                      )}
                    </Row>
                  </FormGroup>

                  <FormGroup className="FormGroup">
                    <LabelText require>주소</LabelText>
                    <Row>
                      <Col desktop="auto">
                        <InputText
                          name="zipCode"
                          ref={register({
                            required: {
                              value: true,
                              message: `주소를 입력해주세요.`,
                            },
                          })}
                          readOnly
                        />
                      </Col>
                      <RightCol desktop="none">
                        <AddressButton
                          onSelectAddress={(address) => {
                            setValue(`zipCode`, address.zonecode);
                            setValue(`address`, address.address);
                          }}
                          renderButton={(buttonProps) => (
                            <FormButton
                              {...buttonProps}
                              outline
                              css={`
                                ${breakpoint(768)} {
                                  margin-top: 0 !important;
                                }
                              `}
                            >
                              주소검색
                            </FormButton>
                          )}
                        />
                      </RightCol>
                    </Row>
                    <Row>
                      <InputText name="address" ref={register} readOnly />
                    </Row>
                    <Row>
                      <InputText
                        name="addressDetail"
                        placeholder="상세주소 입력"
                        ref={register}
                      />
                    </Row>
                    <FormTextTip>
                      교육자료를 보내 드리니 정확한 주소를 기입하시기 바랍니다.
                    </FormTextTip>
                  </FormGroup>
                </article>

                <article>
                  <h2>
                    <Tit size="s4-1">관심있는 SGDs 목표 (중복선택가능)</Tit>
                  </h2>

                  <Toggle className="flex">
                    {chkData.map((row) => (
                      <li key={row.id}>
                        <InputCheckbox
                          className="chk-label"
                          label={row.label}
                          name={`answer${row.id}`}
                          ref={register}
                          onChange={() => {
                            setAnswer(false);
                          }}
                        />
                      </li>
                    ))}
                  </Toggle>
                  <InputCheckbox
                    label="기타"
                    onChange={() => {
                      setAnswer(true);
                    }}
                  />
                  <InputText
                    type="text"
                    placeholder="기타 입력"
                    name="answer18"
                    ref={register}
                    className="input"
                    disabled={!answer}
                    css="margin-top:8px;"
                  />
                </article>

                <article>
                  <h2>
                    <Tit size="s4-1">신청경로</Tit>
                  </h2>
                  <InputRadioGroup full>
                    <ul className="radio-list">
                      <li>
                        <InputRadio
                          id="route1"
                          name="route"
                          ref={register}
                          label="유니세프 홈페이지 및 SNS"
                          value="유니세프 홈페이지 및 SNS"
                          defaultChecked
                          onChange={() => {
                            setRouteEtc(false);
                          }}
                        />
                      </li>
                      <li>
                        <InputRadio
                          id="route2"
                          name="route"
                          ref={register}
                          label="관할 교육지원청"
                          value="관할 교육지원청"
                          onChange={() => {
                            setRouteEtc(false);
                          }}
                        />
                      </li>
                      <li>
                        <InputRadio
                          id="route3"
                          name="route"
                          ref={register}
                          label="주변의 권유"
                          value="주변의 권유"
                          onChange={() => {
                            setRouteEtc(false);
                          }}
                        />
                      </li>
                      <li>
                        <InputRadio
                          label="기타"
                          id="route4"
                          ref={register}
                          name="route"
                          onChange={() => {
                            setRouteEtc(true);
                          }}
                        />
                        <InputText
                          name="route"
                          type="text"
                          placeholder=""
                          className="input"
                          disabled={!routeEtc}
                        />
                      </li>
                    </ul>
                  </InputRadioGroup>
                </article>

                <br />
                <br />
                <StepHead>
                  <h2>
                    <Tit size="s3">유니세프 서비스 이용 약관 동의</Tit>
                  </h2>
                </StepHead>
                <FormGroup className="chk-group full">
                  <InputCheckbox
                    label="[필수] 개인정보 수집 ∙ 이용 동의"
                    name="isAgreeYn1"
                    ref={register({
                      required: {
                        value: true,
                        message: `개인정보 수집 ∙ 이용 동의에 체크해주세요.`,
                      },
                    })}
                  />
                  <AgreeTable>
                    <table>
                      <tbody>
                        <tr>
                          <th>목적</th>
                          <th>항목</th>
                          <th>보유기간</th>
                        </tr>
                        <tr>
                          <td>교육자료, 기념품 발송 및 확인</td>
                          <td>이름, 소속, 연락처, 이메일주소, 자택주소</td>
                          <td className="ellipsis">배송 확인 후 즉시 파기</td>
                        </tr>
                      </tbody>
                    </table>
                  </AgreeTable>
                  {errors.isAgreeYn1 && (
                    <ErrorMessage>{errors.isAgreeYn1.message}</ErrorMessage>
                  )}
                  <p className="tip">
                    <FormTextTip>
                      위의 개인정보 수집 ∙ 이용에 동의하지 않을 경우 자료신청이
                      제한됩니다.
                    </FormTextTip>
                  </p>
                </FormGroup>
                <FormGroup className="chk-group full">
                  <InputCheckbox
                    label="[선택] 개인정보 수집 ∙ 이용 동의"
                    name="isAgreeYn2"
                    ref={register}
                  />
                  <AgreeTable>
                    <table>
                      <tr>
                        <th>목적</th>
                        <th>항목</th>
                        <th>보유기간</th>
                      </tr>
                      <tr>
                        <td>아동권리 교육 및 행사 안내</td>
                        <td>이름, 소속, 연락처, 이메일주소, 자택주소</td>
                        <td className="ellipsis">2년</td>
                      </tr>
                    </table>
                  </AgreeTable>
                  <p className="tip">
                    <FormTextTip>
                      위의 개인정보 수집 ∙ 이용에 동의하지 않더라도 자료신청이
                      가능합니다.
                    </FormTextTip>
                  </p>
                </FormGroup>
              </div>
              <div className="btn-wrap">
                <Button full type="submit">
                  신청하기
                </Button>
              </div>
            </FormContainer>
          </Container>
        </form>
      </Section>

      <Section className="by-sub-main-layout">
        <Container>
          <ContactUs itemData={contactData} />
        </Container>
      </Section>
    </LayoutWithTitle>
  );
};
export default Apply;
